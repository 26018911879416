import { Routes } from '@angular/router';
import { OnboardingGuard } from 'src/shared/guards/onboarding.guard';
import { RegistrationGuard } from 'src/shared/guards/registered.guard';

export const OnboardingRoutes: Routes = [
  {
    path: 'onboarding',
    loadComponent: () => import('./onboarding/onboarding.component').then(m => m.OnboardingComponent),
    canActivate: [OnboardingGuard],
    canDeactivate: [RegistrationGuard],
    children: [
      {
        path: 'user',
        loadComponent: () => import('./useronboarding/useronboarding.component').then(m => m.UserOnboardingComponent),
        canDeactivate: [RegistrationGuard]
      },
      {
        path: 'policy',
        loadComponent: () => import('./addpolicy/addpolicy.component').then(m => m.AddPolicyComponent),
        canDeactivate: [RegistrationGuard]
      },
      {
        path: 'pet',
        loadComponent: () => import('./petonboarding/petonboarding.component').then(m => m.PetOnboardingComponent),
        canDeactivate: [RegistrationGuard]
      },
    ]
  },
  {
    path: 'account-cannot-be-verified',
    loadComponent: () => import('./cannotverify/cannotverify.component').then(m => m.CannotVerifyComponent),
  },
];
