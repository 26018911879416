import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, switchMap, take, throwError } from "rxjs";
import { accountexternal, entities, scexternal } from "../../../shared/services/client/client";
import { catchError, map, retry, retryWhen, tap } from "rxjs/operators";
import { SymptomcheckerDataService } from "../symptomchecker.data.service/symptomchecker.data.service";
import { AccountDataService } from "../../../app/services/account.data.service/account.data.service";
import { has, isNil, last, startCase } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { PetDataService } from 'src/app/services/pet.data.service/pet.data.service';
import { MixpanelService } from 'src/shared/services/mix-panel.service/mix-panel.service';
import { AlgorithmsDataService } from 'src/shared/services/algorithms.data.service/algorithms.data.service';

@Injectable()
export class SessionStateService implements OnDestroy {
  // Services
  accountService = inject(AccountDataService);
  toastService = inject(ToastrService);
  symptomcheckerService = inject(SymptomcheckerDataService);
  algorithmService = inject(AlgorithmsDataService);
  petService = inject(PetDataService);
  private readonly sessionKey = 'scsession';
  private readonly algorithmIDKey = 'scalgorithmid';
  private petDocIDSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private petDetailsSubject: BehaviorSubject<entities.PetResponse | null> = new BehaviorSubject<entities.PetResponse | null>(null);
  private petsSubject: BehaviorSubject<entities.PetResponse[]> = new BehaviorSubject<entities.PetResponse[]>([]);
  private algorithmDocIDSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private currentNodeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private outcomeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private algorithmSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private pendingJobsSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private disableControlAnimationSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscription = new Subscription();

  constructor() { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get controlsAnimationDisabled(): Observable<boolean> {
    return this.disableControlAnimationSubject.asObservable();
  }

  get loading(): Observable<boolean> {
    return this.loadingSubject.asObservable()
  }

  get currentNode(): Observable<scexternal.Node | null> {
    return this.currentNodeSubject.asObservable();
  }

  get currentType(): Observable<'Question' | 'Outcome'> {
    return combineLatest([this.currentNode, this.outcome]).pipe(
      map(result => {
        if (!isNil(last(result))) {
          return 'Outcome'
        }
        return 'Question'
      })
    );
  }

  get outcome(): Observable<scexternal.Outcome | null> {
    return this.outcomeSubject.asObservable();
  }

  get currentUser(): Observable<accountexternal.GetUserResponse> {
    return this.accountService.GetCurrentUser();
  }

  get algorithm(): Observable<any> {
    return of(this.algorithmSubject.value);
  }

  get jobsPending(): Observable<boolean> {
    return this.pendingJobsSubject.asObservable();
  }

  set session(sessionId: string) {
    const session: scexternal.Session = {
      session_id: sessionId,
    }
    localStorage.setItem(this.sessionKey, JSON.stringify(session));
  }

  get session(): scexternal.Session {
    const sessionString = localStorage.getItem(this.sessionKey);
    return sessionString ? JSON.parse(sessionString) : null;
  }

  resetSession(): void {
    localStorage.removeItem(this.sessionKey);
    localStorage.removeItem(this.algorithmIDKey);
  }

  set algorithmID(docID: string) {
    this.algorithmDocIDSubject.next(docID);
    localStorage.setItem(this.algorithmIDKey, docID);
  }

  set petDocID(docID: string) {
    this.petDocIDSubject.next(docID);
    this.setPetDetails(docID);
  }

  get petDocID(): string {
    return this.petDocIDSubject.value;
  }

  get petDetails() {
    return this.petDetailsSubject.value;
  }

  set pets(pets: entities.PetResponse[]) {
    this.petsSubject.next(pets);
  }

  get pets() {
    return this.petsSubject.value;
  }

  get algorithmID(): string {
    return this.algorithmDocIDSubject.value;
  }

  get cachedAlgorithmID(): string | null {
    return localStorage.getItem(this.algorithmIDKey);
  }

  startSession() {
    return this.currentUser.pipe(
      tap(() => this.loadingSubject.next(true)),
      switchMap(user => {
        return this.symptomcheckerService.StartSession({
          algorithm_id: this.algorithmDocIDSubject.value || '',
          pet_doc_id: this.petDocIDSubject.value || '',
        })
      }),
      tap(() => {
        this.loadingSubject.next(false)
      }),
      map(session => {
        this.session = session.session.session_id;
        this.currentNodeSubject.next(session.current_node);
        return session.current_node;
      })
    )
  }

  setCurrentNode() {
    this.loadingSubject.next(true);
    
    this.symptomcheckerService.GetCurrentNode(this.session?.session_id).pipe(
      catchError(err => {
        console.error('Get current node error', err);
        this.loadingSubject.next(false);
        this.toastService.error(startCase(err?.message) || 'Unable to get current question. Please try again.', undefined, { timeOut: 10000, positionClass: 'toast-bottom-right' });
        return of(null);
      }),

      tap(result => {
        if (!result) return;
        this.setCurrentNodeValue(result);
      }),
      tap(() => {
        this.loadingSubject.next(false);
      }),
      take(1),
    ).subscribe();
  }

  nextQuestion(submission: scexternal.SessionNextRequest): void {
    this.loadingSubject.next(true);
    const source = this.symptomcheckerService.NextQuestion(submission).pipe(
      switchMap((result) => {
        if (result.pending_jobs && result?.pending_jobs?.length > 0) {
          this.pendingJobsSubject.next(true);
          return throwError(() => new Error('Pending jobs'));
        }
        return of(result);
      }),
      catchError(err => {
        if (err.message === 'Pending jobs') {
          return throwError(() => new Error('Pending jobs'));
        }
        console.error('Next question error', err);
        this.loadingSubject.next(false);
        this.toastService.error(startCase(err?.message) || 'Unable to get next question. Please try again.', undefined, { timeOut: 10000, positionClass: 'toast-bottom-right' });
        return of(null);
      }),
      retry({ delay: 5000 })
    );
    this.subscription.add(source.pipe(
      map(result => {
        if (!result) return;
        this.loadingSubject.next(false);
        this.disableControlAnimationSubject.next(true);
        this.pendingJobsSubject.next(false);
        this.setNextQuestionValue(result);
      })
    ).subscribe());
  }

  previousNode(previousRequest: scexternal.SessionPreviousRequest): void {
    this.loadingSubject.next(true);
    this.subscription.add(this.symptomcheckerService.PreviousNode(previousRequest).pipe(
      catchError(err => {
        this.loadingSubject.next(false);
        this.toastService.error(startCase(err?.message) || 'Unable to go back to previous question. Please try again.', undefined, { timeOut: 10000, positionClass: 'toast-bottom-right' });
        return of(null);
      }),
      tap(result => {
        this.loadingSubject.next(false);
        this.disableControlAnimationSubject.next(true);
        if (!result) return;
        this.setPreviousNodeValue(result);
      })
    ).subscribe());
  }

  restartSession() {
    this.loadingSubject.next(true);
    this.resetSession();
    this.currentNodeSubject.next(null);
    this.outcomeSubject.next(null);
    this.disableControlAnimationSubject.next(false);
    return this.startSession();
  }

  getPets() {
    this.loadingSubject.next(true);
    return of(this.pets).pipe(
      tap(() => this.loadingSubject.next(false)),
      switchMap(() => this.petService.ListMyPets()),
      map(pets => {
        this.pets = pets.pets;
        this.loadingSubject.next(false);
        return pets.pets;
      }),
      catchError(err => {
        this.loadingSubject.next(false);
        this.toastService.error(startCase(err?.message) || 'Unable to get pets. Please try again.', undefined, { timeOut: 10000, positionClass: 'toast-bottom-right' });
        return of([]);
      })
    );
  }

  setCurrentNodeValue(value: Partial<scexternal.SessionCurrentNodeResponse>) {
    this.currentNodeSubject.next(value?.current_node || null);
    if (value && has(value, 'outcome')) {
      this.outcomeSubject.next(value.outcome || null);
    }
  }

  setNextQuestionValue(value: scexternal.SessionNextResponse) {
    this.currentNodeSubject.next(value?.node || null);
    if (value && has(value, 'outcome')) {
      this.outcomeSubject.next(value.outcome || null);
    }
  }

  private setPreviousNodeValue(value: scexternal.SessionPreviousResponse) {
    this.currentNodeSubject.next(value?.node || null);
  }

  private setPetDetails(petDocID: string) {
    if (!this.petDetailsSubject.value || this.petDetailsSubject.value?.doc_id !== petDocID) {
      this.subscription.add(this.petService.GetMyPet(petDocID).pipe(
        catchError(err => {
          this.toastService.error(startCase(err?.message) || 'Unable to get pet details. Please try again.', undefined, { timeOut: 10000, positionClass: 'toast-bottom-right' });
          return of(null);
        }),
        tap(pet => {
          this.petDetailsSubject.next(pet);
        })
      ).subscribe());
    }
  }

}
