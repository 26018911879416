import {inject, Injectable} from '@angular/core';
import {ClientService} from "../../../shared/services/client/client.service";
import {Observable, switchMap} from "rxjs";
import {scexternal, scinternal} from "../../../shared/services/client/client";

@Injectable({
  providedIn: 'root'
})
export class SymptomcheckerDataService {
  clientService = inject(ClientService);
  constructor() { }

  StartSession(session: scexternal.CreateSessionRequest) {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.StartSession(session)))
  }

  /**
   * Returns the current active question, outcome, information in the users session
   */
  GetCurrentNode(sessionID: string) {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.GetSessionCurrentNode(sessionID)))
  }

  NextQuestion(answer: scexternal.SessionNextRequest) {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.SessionNext(answer)))
  }

  PreviousNode(previousSession: scexternal.SessionPreviousRequest) {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.SessionPrevious(previousSession)))
  }

  ListUserSessions(params: scexternal.ListUserSessionsRequest) {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.ListUserSessions(params)))
  }

  GetSessionOutcome(session_doc_id: string): Observable<scexternal.SessionOutcomeResponse> {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.GetUserSessionOutcome(session_doc_id)))
  }

  GetUserSession(session_doc_id: string): Observable<scexternal.SessionResponse> {
    return this.clientService.client.pipe(switchMap(client => client.scexternal.GetUserSession(session_doc_id)))
  }

  ListSymptomCheckerSummaries(params: scinternal.ListSymptomCheckerSummariesParams): Observable<scinternal.ListSymptomCheckerSummariesResponse> {
    return this.clientService.client.pipe(switchMap(client => client.scinternal.ListSymptomCheckerSummaries(params)))
  }

}
