import { Routes } from "@angular/router";
import { NoPetsGuard } from "src/app/booking/guards/pet/nopets.guard";
import { LoginGuard } from "src/shared/guards/login.guard";
import { UnsavedChangesGuard } from "src/shared/guards/unsavedChangesGuard.guard";
import { SessionStateService } from "./services/symptomcheckersession/session-state.service";
import { CtaHandlerService } from "./services/cta-handler/cta-handler.service";

export const SymptomCheckerRoutes: Routes = [
    {
      path: 'symptomchecker/:petID',
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/sc-dashboard/sc-dashboard.component').then(m => m.ScDashboardComponent),
      loadChildren: () => DashboardChildRoutes,
    },
    {
      path: 'symptomchecker',
      canActivate: [LoginGuard, NoPetsGuard],
      loadComponent: () => import('./pages/sc-pet-selection/sc-pet-selection.component').then(m => m.ScPetSelectionComponent),
    },
    {
      path: 'symptomchecker/session/:docID',
      canActivate: [LoginGuard],
      canDeactivate: [UnsavedChangesGuard],
      loadComponent: () => import('./pages/web-sc-page/web-sc-page.component').then(m => m.WebScPageComponent),
    },
    {
      path: 'recommendations',
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/sc-recommendations/sc-recommendations.component').then(m => m.SymptomCheckerRecommendationsComponent),
    },
    {
      path: 'symptomchecker/history/all',
      canActivate: [LoginGuard],
      loadComponent: () => import('./pages/sc-full-session-history/sc-full-session-history.component').then(m => m.ScFullSessionHistoryComponent),
    },
    {
      path: 'symptomchecker/history/preview/:docID/:petID',
      canActivate: [LoginGuard],
      providers: [SessionStateService, CtaHandlerService],
      loadComponent: () => import('./pages/sc-session-outcome/sc-session-outcome.component').then(m => m.ScSessionOutcomeComponent),
      
    }
  ];

 
  const DashboardChildRoutes: Routes = [
    {
      path: '',
      loadComponent: () => import('./pages/sc-dashboard-featured/sc-dashboard-featured.component').then(m => m.ScDashboardFeaturedComponent),
    },
    {
      path: 'categories',
      loadComponent: () => import('./pages/sc-all-symptoms/sc-all-symptoms.component').then(m => m.ScAllSymptomsComponent)
    },
    {
     path: 'categories/:categoryID',
     loadComponent: () => import('./pages/sc-category/sc-category.component').then(m => m.ScCategoryComponent) 
    }
  ]